<template>
  <div class="app-users-wrapper">
    <div v-show="active === null">
      <div class="title-and-buttons">
        <div class="title">
          <h1 v-if="currentUser.role === 'dentist'">Пациенти</h1>
          <h1 v-else>Потребители</h1>
          <p class="accent">
            <template v-if="currentUser.role !== 'dentist'">
              <template v-if="currentUser.role === 'admin'"
                >{{ totals.admin || 0 }} админ{{
                  totals.admin !== 1 ? "и" : null
                }},</template
              >
              {{ totals.employee || 0 }} служител{{
                totals.employee !== 1 ? "и" : null
              }}, {{ totals.dentist || 0 }} лекар{{
                totals.dentist !== 1 ? "и" : null
              }},
            </template>
            {{ totals.pacient || 0 }} пациент{{
              totals.pacient !== 1 ? "и" : null
            }}
          </p>
        </div>
        <div class="search-and-button">
          <div class="search-wrapper">
            <span class="icon">V</span>
            <span class="icon clear" v-if="search" @click="clear">L</span>
            <input
              ref="searchInput"
              type="text"
              class="search"
              :placeholder="`ТЪРСИ В ${
                currentUser.role === 'dentist' ? 'ПАЦИЕНТИ' : 'ПОТРЕБИТЕЛИ'
              }`"
              @keyup="handleSearchInput($event)"
            />
          </div>
          <Button class="dark" @click="newUser()">
            <span class="icon">T</span>
            <span class="desktop">
              НОВ
              <template v-if="currentUser.role === 'dentist'">ПАЦИЕНТ</template>
              <template v-else>ПОТРЕБИТЕЛ</template>
            </span>
          </Button>
        </div>
        <div class="btns-mobile">
          <MobileButton @click="newUser()">
            <!-- <span class="icon">T</span> -->
            <span>НОВ ПАЦИЕНТ</span>
          </MobileButton>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="table" v-if="users.length">
          <thead>
            <tr>
              <th>
                <div class="label-and-sort">
                  <span>ПОТРЕБИТЕЛ</span>
                  <template
                    v-if="role === 'pacient' || currentUser.role === 'dentist'"
                  >
                    <span
                      class="arrow"
                      :class="{ active: $route.query.sort === 'user:asc' }"
                      @click="setSort('user:asc')"
                      >⬆</span
                    >
                    <span
                      class="arrow"
                      :class="{ active: $route.query.sort === 'user:desc' }"
                      @click="setSort('user:desc')"
                      >⬇</span
                    >
                  </template>
                </div>
              </th>
              <th>ПОТРЕБИТЕЛСКО ИМЕ</th>
              <th>ЕГН</th>
              <th>
                <div class="label-and-sort">
                  <span>ПОСЛЕДНО ИЗСЛЕДВАНЕ</span>
                  <template
                    v-if="role === 'pacient' || currentUser.role === 'dentist'"
                  >
                    <span
                      class="arrow"
                      :class="{
                        active: $route.query.sort === 'lastExaminationAt:asc',
                      }"
                      @click="setSort('lastExaminationAt:asc')"
                      >⬆</span
                    >
                    <span
                      class="arrow"
                      :class="{
                        active: !$route.query.sort,
                      }"
                      @click="setSort('')"
                      >⬇</span
                    >
                  </template>
                </div>
              </th>
              <th>БР. ИЗСЛЕДВАНИЯ</th>
              <th>БР. СНИМКИ</th>
              <template v-if="currentUser.role !== 'dentist'">
                <th>РОЛЯ</th>
                <th>ПОСЛЕДНО ВЛИЗАНЕ</th>
                <th>СТАТУС</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in users" :key="u._id" @click="toggleUser(u)">
              <td>
                <p>{{ u.name }}</p>
                <p class="sub" v-if="u.email">{{ u.email }}</p>
              </td>
              <td>{{ u.username }}</td>
              <td>{{ u.egn }}</td>
              <td>
                <template v-if="u.lastExamination">
                  <p>{{ u.lastExamination.name }}</p>
                  <p class="sub">
                    {{ u.lastExamination.createdAt | formatDateFull }}
                  </p>
                </template>
              </td>
              <td>{{ u.examinationQuantity }}</td>
              <td>{{ u.photosQuantity }}</td>
              <template v-if="currentUser.role !== 'dentist'">
                <td>
                  <Role :role="u.role" />
                </td>
                <td>{{ u.loggedAt | formatDateFull }}</td>
                <td>
                  <Slider
                    v-if="u.role !== 'pacient' && u.role !== 'admin'"
                    :value="u.active"
                    @change="toggleActive($event, u._id)"
                  />
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <Pagination :total="total" :size="size" />
      </div>
      <div class="help-image" v-if="loaded">
        <img
          src="@/assets/img/help/helpPatients.svg"
          alt="Стъпки"
          v-if="!users.length && !search.length"
        />
        <img
          src="@/assets/img/help/noResults.svg"
          alt="Няма резултати"
          v-else-if="!users.length && search.length"
        />
        <img src="@/assets/img/help/helpPatientsS.svg" alt="Стъпки" v-else />
      </div>
    </div>
    <!-- OPENED TAB -->
    <User
      v-for="(u, i) in userTabs"
      :u="u"
      :key="`user-${u._id}`"
      v-show="active === i"
      @update="userUpdatedFromTab($event, i)"
    />
    <!-- FIXED TAB BAR -->
    <div class="tabs">
      <div
        class="tab tab--all"
        :class="{ 'tab--active': active === null, dropped: dropup }"
        @click="toggleFirst()"
      >
        <span class="tab-username" v-if="role === ''">
          ВСИЧКИ
          <template v-if="currentUser.role === 'dentist'">ПАЦИЕНТИ</template>
          <template v-else>ПОТРЕБИТЕЛИ</template>
        </span>
        <span class="tab-username" v-else-if="role === 'admin'"
          >АДМИНИСТРАТОРИ</span
        >
        <span class="tab-username" v-else-if="role === 'employee'"
          >МДЛ СЛУЖИТЕЛИ</span
        >
        <span class="tab-username" v-else-if="role === 'dentist'">ЛЕКАРИ</span>
        <span class="tab-username" v-else-if="role === 'pacient'"
          >ПАЦИЕНТИ</span
        >
        <span
          class="icon"
          v-if="currentUser.role === 'admin' || currentUser.role === 'employee'"
          >q</span
        >
        <div
          class="drop-up"
          v-if="dropup && this.currentUser.role !== 'dentist'"
          @click.stop
        >
          <p :class="{ active: role === '' }" @click="selectRole('')">
            ВСИЧКИ ПОТРЕБИТЕЛИ
          </p>
          <p
            :class="{ active: role === 'admin' }"
            @click="selectRole('admin')"
            v-if="currentUser.role === 'admin'"
          >
            АДМИНИСТРАТОРИ
          </p>
          <p
            :class="{ active: role === 'employee' }"
            @click="selectRole('employee')"
          >
            МДЛ СЛУЖИТЕЛИ
          </p>
          <p
            :class="{ active: role === 'dentist' }"
            @click="selectRole('dentist')"
          >
            ЛЕКАРИ
          </p>
          <p
            :class="{ active: role === 'pacient' }"
            @click="selectRole('pacient')"
          >
            ПАЦИЕНТИ
          </p>
        </div>
      </div>
      <div
        class="tab"
        :class="{ 'tab--active': active === i }"
        :key="`tab-${i}`"
        v-for="(u, i) in userTabs"
        @click="active = i"
      >
        <span class="tab-username">{{ u.name }}</span>
        <span class="icon close" v-if="active === i" @click.stop="closeTab(i)"
          >L</span
        >
      </div>
    </div>
    <!-- REGISTRATION PANEL -->
    <UserPanel
      v-if="isUserPanelOpen"
      @close-panel="panelClosed"
      :id="selectedUser"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import User from "./User";
import UserPanel from "@/views/panels/UserPanel";
import Pagination from "@/components/Pagination";
import Button from "@/components/Button";
import MobileButton from "@/components/MobileButton";
import Role from "@/components/Role";
import Slider from "@/components/Slider";
export default {
  components: {
    User,
    Pagination,
    UserPanel,
    Button,
    MobileButton,
    Role,
    Slider,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    $route() {
      this.getUsers();
    },
    search() {
      this.getData();
    },
    active() {
      this.dropup = false;
    },
  },
  data() {
    return {
      isUserPanelOpen: false,
      selectedUser: null,
      users: [],
      userTabs: [],
      totals: {},
      total: 0,
      size: 20,
      timeout: null,
      search: "",
      active: null,
      dropup: false,
      role: "",
      loaded: false,
    };
  },
  methods: {
    setSort(s) {
      this.$router.push({
        query: { page: 1, sort: s === this.$route.query.sort ? "" : s },
      });
    },
    toggleFirst() {
      if (this.active !== null) {
        this.active = null;
      } else {
        this.dropup = !this.dropup;
      }
    },
    selectRole(r) {
      this.role = r;
      this.active = null;
      this.dropup = false;
      if (this.$route.query.page !== 1) {
        this.$router.push({ query: { page: 1 } });
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    clear() {
      this.search = "";
      this.$refs.searchInput.value = "";
    },
    handleSearchInput(e) {
      clearTimeout(this.timeout);

      // Make a new timeout set to go off in 1000ms (1 second)
      this.timeout = setTimeout(() => {
        this.search = e.target.value;
      }, 1000);
    },
    newUser() {
      this.isUserPanelOpen = true;
    },
    editUser(id) {
      this.selectedUser = id;
      this.isUserPanelOpen = true;
    },
    getBirthday(egn) {
      let output = "";

      if (egn) {
        const d = egn.slice(4, 6);
        let m = Number.parseInt(egn.slice(2, 4));
        let y = egn.slice(0, 2);

        if (m > 40) {
          m -= 40;
          y = `20${y}`;
        } else if (m > 20) {
          m -= 20;
          y = `18${y}`;
        } else {
          y = `19${y}`;
        }

        output = `${d}/${m < 10 ? "0" : ""}${m}/${y}`;
      }

      return output;
    },
    getLastExamination(u) {
      let url = `/examinations/user/last/${u._id}`;

      this.$apiService.get(url).then((response) => {
        this.$set(u, "lastExamination", response.data);
      });
    },
    getExaminationCount(u) {
      let url = `/examinations/user/count/${u._id}`;

      this.$apiService.get(url).then((response) => {
        this.$set(u, "examinationQuantity", response.data || 0);
      });
    },
    getPhotosCount(u) {
      let url = `/photos/user/count/${u._id}`;

      this.$apiService.get(url).then((response) => {
        this.$set(u, "photosQuantity", response.data || 0);
      });
    },
    getUsers(open) {
      this.loaded = false;
      this.users = [];
      let url = `/users?size=${this.size}&page=${this.$route.query.page || 1}`;

      if (this.search) {
        url += `&search=${this.search}`;
      }

      if (this.role) {
        url += `&role=${this.role}`;
      }

      if (
        (this.role === "pacient" || this.currentUser.role === "dentist") &&
        this.$route.query.sort
      ) {
        url += `&sort=${this.$route.query.sort}`;
      }

      this.$apiService.get(url).then((response) => {
        this.users = response.data.users;
        this.total = response.data.total;

        this.users.map((u) => {
          // if (this.currentUser.role === 'dentist') {
          this.getLastExamination(u);
          // }
          if (u.role === "pacient") {
            this.getExaminationCount(u);
            this.getPhotosCount(u);
          }
          // if (u.egn) {
          //   u.birthday = this.getBirthday(u.egn);
          // }
          if (open === u._id) {
            this.toggleUser(u);
          }
        });

        this.loaded = true;
      });
    },
    toggleUser(u) {
      if (this.currentUser.role === "employee" && u.role === "admin") {
        return this.$snotify.error("Нямате права да редактирате админи!");
      }

      if (u.role === "pacient") {
        const i = this.userTabs.map((ut) => ut._id).indexOf(u._id);
        if (i === -1) {
          this.userTabs.push(u);
          this.active = this.userTabs.length - 1;
        } else {
          this.active = i;
        }
      } else {
        this.isUserPanelOpen = true;
        this.selectedUser = u._id;
      }
    },
    userUpdatedFromTab(u, i) {
      this.getData();
      this.$set(this.userTabs, i, u);
    },
    closeTab(i) {
      this.userTabs.splice(i, 1);
      this.active = null;
    },
    getTotal() {
      this.$apiService.get("/users/total").then((response) => {
        for (let total of response.data) {
          this.$set(this.totals, total._id, total.total);
        }
      });
    },
    toggleActive(e, id) {
      this.$apiService.put(`/users/${id}?active=${e}`).then(() => {
        this.$snotify.success("Потребителят е обновен!");
      });
    },
    panelClosed(e) {
      this.isUserPanelOpen = false;
      this.selectedUser = null;
      if (e.update) {
        this.getData(e.open);
      }
    },
    getData(open) {
      this.getUsers(open);
      this.getTotal();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
.app-users-wrapper {
  padding-bottom: $user-tabs-height;
}
.title-and-buttons {
  display: flex;
  justify-content: space-between;
}
.search-and-button {
  align-items: center;
  display: flex;
  .search-wrapper {
    border-bottom: 1px solid #e8e8e8;
    height: $input-height;
    margin-right: 10px;
    position: relative;
    width: 340px;
    .icon {
      font-size: 18px;
      left: 10px;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));

      &.clear {
        left: initial;
        right: 10px;
        @include hover();
      }
    }
    input {
      border: none;
      color: #000;
      font-weight: 700;
      height: 100%;
      outline: none;
      width: 100%;
      padding: 0 40px;
      &::placeholder {
        color: #000;
        font-weight: 700;
      }
    }
  }
}

.help-image {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  img {
    height: auto;
    max-width: 100%;
  }
}

.tabs {
  align-items: flex-start;
  background-color: #406083;
  bottom: 0;
  display: flex;
  height: $user-tabs-height;
  left: $nav-width;
  padding: 0 10px 0 0;
  position: fixed;
  width: calc(100% - #{$nav-width});
  z-index: 5;
  .tab {
    align-items: center;
    background-color: #5e80a5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 36px;
    justify-content: space-between;
    margin-right: 5px;
    padding: 0 5px;
    width: 200px;
    @include noselect();
    @include transition(all $transition $cubic);
    &.tab--all {
      position: relative;
      .icon {
        margin-right: 5px;
        // @include transition(all $transition $cubic);
      }
      &.dropped {
        .icon {
          @include transform(scaleY(-1));
        }
      }
      .drop-up {
        background-color: #fff;
        color: #000;
        bottom: 100%;
        -webkit-box-shadow: 0px -4px 40px -20px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px -4px 40px -20px rgba(0, 0, 0, 0.75);
        box-shadow: 0px -4px 40px -20px rgba(0, 0, 0, 0.75);
        left: 0;
        padding: 10px 10px;
        position: absolute;
        width: 100%;
        p {
          font-weight: 700;
          margin: 5px 0 12px 0px;
          font-size: 0.95rem;
          @include hover();
          &.active {
            color: $blue;
          }
        }
      }
    }
    &:hover {
      background-color: rgb(236, 236, 236);
      color: #020101;
    }
    &.tab--active {
      background-color: #fff;
      color: #000;
    }
    .tab-username {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
    .close {
      align-items: center;
      background-color: #b82424;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      font-size: 0.8rem;
      display: inline-flex;
      height: 20px;
      justify-content: center;
      min-height: 20px;
      min-width: 20px;
      width: 20px;
      @include transition(all $transition $cubic);
      &:hover {
        background-color: #a11818;
      }
    }
  }
}

.btns-mobile {
  display: none;
}

.label-and-sort {
  align-items: center;
  display: inline-flex;

  .arrow {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #00c2a9;
    color: #00c2a9;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    height: 24px;
    justify-content: center;
    margin-left: 4px;
    padding: 4px;
    @include transition(all $transition $cubic);
    width: 24px;

    &:hover {
      background: #00c2a811;
    }

    &.active {
      background: #00c2a9;
      color: #fff;
    }
  }
}

@media screen and (max-width: $l) {
  .search-and-button .search-wrapper {
    width: 300px;
  }
}
@media screen and (max-width: $xsm) {
  .tabs {
    bottom: $sm-nav-height;
    left: 0;
    overflow: auto visible;
    max-width: 100vw;
    width: 100%;
    z-index: 1;
    @include scrollbar(5px, #004393);
    .tab {
      min-width: 200px;
      &.tab--all {
        .drop-up {
          bottom: $sm-nav-height + $user-tabs-height;
          position: fixed;
          width: 100%;
          p {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .button,
  .search-wrapper {
    display: none;
  }
  .btns-mobile {
    bottom: 10px + $sm-nav-height + $user-tabs-height;
    display: flex;
    right: 10px;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    .mobile-button:not(:first-child) {
      margin-top: 5px;
    }
  }
  .help-image {
    img {
      height: auto;
      width: 100%;
    }
  }
}

@media print {
  .tabs {
    display: none;
  }
  .help-image {
    display: none;
  }
}
</style>
