<template>
  <div class="user">
    <!-- HEADER WITH USER INFO AND EDIT TOGGLE -->
    <header>
      <div class="header-top">
        <div class="texts">
          <h1>
            {{ user.name }}
            <template v-if="user.birthday">({{ getBirthday }} г.)</template>
          </h1>
          <p class="accent">
            <span
              v-for="(d, i) in user.dentists"
              :key="`dentist-${i}`"
            >{{ i ? `, ${d.name}` : d.name }}</span>
          </p>
        </div>
        <div class="btns">
          <Button
            class="dark"
            @click="print()"
            v-if="currentUser.role === 'admin' || currentUser.role === 'employee'"
            title="Отворете и разпечатайте картончето с данните на пациента"
          >
            <span class="icon">i</span>
            КАРТОНЧЕ
          </Button>
          <Button
            class="dark"
            @click="isUserPanelOpen = true"
            title="Преглед и редактиране на пациентския профил"
          >
            <span class="icon">J</span>
            ПРОФИЛ
          </Button>
          <Button
            class="blue"
            @click="isExaminationPanelOpen = true"
            title="Създайте ново онлайн направление за рентгенография"
          >
            <span class="icon">T</span>
            НОВО ИЗСЛЕДВАНЕ
          </Button>
        </div>
        <div class="btns-mobile">
          <MobileButton @click="isUserPanelOpen = true">
            <span class="icon">J</span>
          </MobileButton>
          <MobileButton @click="isExaminationPanelOpen = true">
            <span class="icon">T</span>
          </MobileButton>
        </div>
      </div>
      <div class="header-bottom">
        <div class="texts">
          <div class="icon">K</div>
          <h3>{{ user.egn || '-' }}</h3>
          <p class="accent">ЕГН</p>
        </div>
        <div class="texts">
          <div class="icon">N</div>
          <h3>{{ user.email || '-' }}</h3>
          <p class="accent">Е-ПОЩА</p>
        </div>
        <div class="texts">
          <div class="icon">X</div>
          <h3>{{ user.phone || '-' }}</h3>
          <p class="accent">ТЕЛЕФОН</p>
        </div>
        <div class="texts">
          <div class="icon">M</div>
          <h3>{{ user.location || '-' }}</h3>
          <p class="accent">АДРЕС</p>
        </div>
      </div>
    </header>
    <div class="content">
      <div class="content__header">
        <div class="texts">
          <h1>История на изследванията</h1>
          <p class="accent">Назначени рентгенографии на пациента</p>
        </div>
        <!-- <div v-if="currentUser.role === 'admin' || currentUser.role === 'employee'">
          <input class="input-id" type="text" v-model="user._id" ref="userId" />
          <Button class="orange" @click="copyUserId()">
            <span class="icon">K</span>
            Копирай ID
          </Button>
        </div>-->
      </div>
      <div class="examinations-wrapper">
        <table class="examinations">
          <tr
            v-for="e in examinations"
            :key="`examination-${e._id}`"
            @click="selectExamination(e)"
            :class="{ 'row--selected': selectedExamination === e._id }"
          >
            <td class="icon">h</td>
            <td>
              <p class="sub">{{ e.createdAt | formatDateFull }}</p>
              <p>{{ e.name }} (от: {{ e.createdBy ? e.createdBy.name : 'Автоматично' }})</p>
              <p class="sub">{{ e.categories }}</p>
            </td>
            <td class="nowrap center desktop">
              <span class="sub">{{ e.photosCount }}</span>
              <span class="sub" v-if="e.photosCount === 1">&nbsp;снимка</span>
              <span class="sub" v-else-if="typeof e.photosCount === 'number'">&nbsp;снимки</span>
            </td>
            <td class="center">
              <Status :status="e.status" />
            </td>
          </tr>
          <tr class="basic" v-if="!examinations.length">
            <td>Пациентът все още няма издадени направления</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- PANEL FOR EDITING THE USER -->
    <UserPanel v-if="isUserPanelOpen" @close-panel="panelClosed" :id="u._id" :tabsSpace="true" />
    <!-- PANEL FOR EDITING THE USER -->
    <ExaminationPanel
      v-if="isExaminationPanelOpen"
      @close-panel="examinationPanelClosed"
      :uid="u._id"
      :id="selectedExamination"
      :tabsSpace="true"
      @refresh-examinations="getExaminations()"
    />
    <PrintCard :u="user" v-if="!isExaminationPanelOpen" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExaminationPanel from '@/views/panels/ExaminationPanel';
import UserPanel from '@/views/panels/UserPanel';
import Button from '@/components/Button';
import MobileButton from '@/components/MobileButton';
import Status from '@/components/Status';
import PrintCard from './PrintCard';
export default {
  props: ['u'],
  computed: {
    ...mapGetters(['currentUser']),
    getBirthday() {
      return this.$moment().diff(
        this.$moment(this.user.birthday.split('/').reverse().join('/')),
        'years'
      );
    },
  },
  components: {
    ExaminationPanel,
    UserPanel,
    Button,
    MobileButton,
    Status,
    PrintCard,
  },
  data() {
    return {
      isUserPanelOpen: false,
      isExaminationPanelOpen: false,
      user: {},
      examinations: [],
      selectedExamination: null,
    };
  },

  methods: {
    print() {
      window.print();
    },
    copyUserId() {
      const input = this.$refs.userId;

      input.select();
      input.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand('copy');

      this.$snotify.success('Потребителското ID е копирано');
    },
    getUser(emit) {
      this.$apiService
        .get(`/users/${this.u._id}?pacient=true`)
        .then((response) => {
          this.user = response.data;
          if (emit) {
            this.$emit('update', this.user);
          }
        });
    },
    getExaminations() {
      this.$apiService
        .get(`/examinations/user/${this.u._id}`)
        .then((response) => {
          this.examinations = response.data;

          this.examinations.map((e) => {
            if (e.canceled) {
              this.$set(e, 'status', 'canceled');
            } else {
              this.$apiService
                .get(`/photos/examination/status/${e._id}`)
                .then((res) => {
                  this.$set(e, 'photosCount', res.data.photos || 0);
                  this.$set(e, 'categories', res.data.categories);
                  this.$set(
                    e,
                    'status',
                    res.data.photos ? 'finished' : 'unfinished'
                  );
                });
            }
          });
        });
    },
    selectExamination(e) {
      if (e.canceled) {
        this.$snotify.error(
          'Направлението е отменено и не може да бъде отваряно.'
        );
      } else {
        this.selectedExamination = e._id;
        this.isExaminationPanelOpen = true;
      }
    },
    examinationPanelClosed(update) {
      this.isExaminationPanelOpen = false;
      this.selectedExamination = null;
      if (update) {
        this.getExaminations();
      }
    },
    panelClosed(e) {
      this.isUserPanelOpen = false;
      if (e.update) {
        this.getUser(true);
      }
    },
  },
  mounted() {
    this.getUser(false);
    this.getExaminations();
    const p = window.location.protocol === 'https:' ? 'wss' : 'ws'
    this.ws = new WebSocket(
      `${p}://${
        process.env.VUE_APP_ENV === 'dev'
          ? `${window.location.hostname}:4004`
          : window.location.host
      }`
    );

    if(this.ws) {
        this.ws.onmessage = (message) => {
          const m = JSON.parse(message.data);
        if (m.name === 'new-examination' && m.user === this.u._id) {
          this.getExaminations();
        }
      };
    }
  },
  destroyed() {
    if(this.ws) {
      this.ws.close();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.user {
  header {
    background-color: #f4f6fc;
    margin: -10px -10px 10px;
    padding: 10px;
    .header-top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .btns {
        display: flex;
        .button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .header-bottom {
      display: flex;
      margin: 20px 0;
      .texts:not(:first-child) {
        margin-left: 20px;
      }
      .icon {
        font-size: 1.85rem;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 1.25rem;
        font-weight: 400;
      }
      p {
        font-size: 0.75rem;
      }
    }
  }
  .content {
    .content__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .input-id {
        left: -100wv;
        opacity: 0;
        position: fixed;
        top: -100vh;
      }
      .texts {
        margin: 20px 0;
      }
    }
  }
}
.examinations-wrapper {
  overflow: auto;
  width: 100%;
  @include scrollbar(5px, #dadada);
}
.examinations {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  tr:not(.basic) {
    cursor: pointer;
    td {
      background-color: #f8f8f8;
      padding: 9px 10px;
      // @include transition(background-color $transition $cubic);
      &:last-child {
        width: 1%;
        white-space: nowrap;
        * {
          vertical-align: middle;
        }
      }
      .action {
        border: 1px solid #b5b5b5;
        background-color: #fff;
        color: #000;
        height: 40px;
        margin-left: 5px;
        width: 40px;
        @include hover();
        &:first-of-type {
          margin-left: 20px;
        }
      }
      p:not(.sub) {
        font-size: 1.1rem;
        line-height: 1.1rem;
        margin: 2px 0;
        font-weight: 700;
        @include transition(color $transition $cubic);
      }
    }
    .icon {
      color: $blue;
      font-size: 1.8rem;
      width: 1%;
      // @include transition(all $transition $cubic);
    }

    &:hover {
      td {
        background-color: #ededed;
      }
    }
    &.row--selected {
      td {
        background-color: #416184;
        p {
          color: #fff;
        }
        span {
          color: rgba($color: #fff, $alpha: 0.6);
        }
        &.icon {
          color: #fff;
        }
      }
    }
  }
}

.btns-mobile {
  display: none;
}

@media screen and (max-width: $xsm) {
  .button {
    display: none;
  }
  .btns-mobile {
    bottom: 10px + $sm-nav-height + $user-tabs-height;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 10px;
    .mobile-button:not(:first-child) {
      margin-top: 5px;
    }
  }

  .user {
    header {
      .header-bottom {
        overflow: auto;
        @include scrollbar(5px, #dadada);
      }
    }
  }
}

@media print {
  .user {
    header,
    .content {
      display: none;
    }
  }
}
</style>