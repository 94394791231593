<template>
  <label class="switch" :class="{ 'switch--active-only': activeOnly }" @click.stop>
    <input type="checkbox" :checked="value" @change="$emit('change', $event.target.checked)" />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: ['value', 'activeOnly']
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;

  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + .slider {
      background-color: $accent;

      &::before {
        @include transform(translateX(20px) scale(2));
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $accent;
    }
  }

  .slider {
    background-color: rgba($color: #000, $alpha: 0.41);
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    @include transition(0.4s);

    &::before {
      background-color: white;
      border-radius: 50%;
      bottom: 7px;
      content: '';
      height: 6px;
      left: 7px;
      position: absolute;
      @include transition(0.4s);
      width: 6px;
    }
  }

  &.switch--active-only {
    input {
      & + .slider {
        background-color: $accent;
      }
      &:not(:checked) + .slider {
        &::before {
          @include transform(scale(2));
        }
      }
    }
  }

  &:hover {
    input {
      &:checked + .slider {
        background-color: rgba($color: $accent, $alpha: 0.41);
      }
      &:not(:checked) + .slider {
        background-color: rgba($color: #000, $alpha: 0.25);
      }
      & + .slider {
        &::before {
          @include transform(translateX(10px) scale(1.5) !important);
        }
      }
    }
  }
}
</style>